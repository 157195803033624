import { supabase } from '../../supabase/functions/supabaseConnect';

const getProjectKeys = async (projectId) => {
    const { data } = await supabase
        .from('keys')
        .select()
        .eq('projectId', projectId)
        .eq('active', true);
    return data;
}

const getKey = async (keyId) => {
    const { data } = await supabase
        .from('keys')
        .select()
        .eq('keyId', keyId)
        .single();
    return data;
}

const insertKey = async (keyData) => {
    const { data } = await supabase
        .from('keys')
        .insert({
            projectId: keyData.projectId,
            name: keyData.name,
            address: keyData.address,
            login: keyData.login,
            password: keyData.password,
        })
        .select()
        .single();
    return data;
}

const updateKey = async (keyId, keyData) => {
    const { data } = await supabase
        .from('keys')
        .update ({
            projectId: keyData.projectId,
            name: keyData.name,
            address: keyData.address,
            login: keyData.login,
            password: keyData.password,
            active: keyData.active
        })
        .eq('keyId', keyId)
        .select()
        .single();
    return data;
}

export { getProjectKeys, getKey, insertKey, updateKey };