import {supabase} from '../../supabase/functions/supabaseConnect';

const getProjects = async () => {
    const { data } = await supabase
        .from('projects')
        .select()
        .eq('active', true);
    return data;    
}

const getProject = async (projectId) => {
    const { data } = await supabase
        .from('projects')
        .select()
        .eq('projectId', projectId)
        .single()
        .select();
    return data;  
}

const insertProject = async (projectName) => {
    const { data } = await supabase
        .from('projects')
        .insert({
            name: projectName
        })
        .select()
        .single();
    return data;
}

export { getProjects, getProject, insertProject };