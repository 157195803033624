import { supabase } from '../../supabase/functions/supabaseConnect';

async function getClients() {

    const { data: clients, error } = await supabase
        .from('clients')
        .select('*')
    if (error) console.log('Error fetching clients:', error)

    return clients
}

export default getClients;