
import React, { useState, useEffect } from 'react';
import { getAuth } from '../functions/getAuth';
import signOut from '../functions/signOut';
import { useSupabaseContext } from '../../supabase/components/SupabaseProvider';
import { useNavigate, useLocation } from 'react-router-dom';
import isUserAdmin from '../../supabase/functions/isUserAdmin';

const AuthContext = React.createContext()

function AuthProvider({ children }) {

    // const [isLoaded, setIsLoaded] = useState(false);
    const [session, setSession] = useState({});
    const [user, setUser] = useState({});
    const [isAdmin, setIsAdmin] = useState(false);

    const { supabase } = useSupabaseContext();
    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        supabase.auth.onAuthStateChange((event, session) => {
            // console.log(event);
            switch (event) {
                case 'SIGNED_OUT':
                    navigate('/login');
                    break;
                case 'SIGNED_IN':
                    setSession(session);
                    if (location.pathname === '/login') {
                        navigate('/')
                    };
                    break;
                default:
                    break;
            }
        })

        const sessionResult = getAuth();

        sessionResult.then((session) => {
            // setIsLoaded(session.sessionLoaded);
            setSession(session.session);
        });
    }, [navigate, supabase.auth, location.pathname]);

    useEffect(() => {
        setUser(session?.user);
    }, [session]);

    useEffect(() => {
        if (user?.id) {
            isUserAdmin(user?.id).then(result => {
                setIsAdmin(result)
            });
        }
    }, [user]);

    // if (!isLoaded) {
    //     return ('loading')
    // }

    return (
        <AuthContext.Provider value={{ session, user, isAdmin, signOut } }>
            {children}
        </AuthContext.Provider>
    )
}

const useAuthContext = () => React.useContext(AuthContext)

export { useAuthContext, AuthProvider }