import { useState, useEffect } from "react";


export default function ProfilePage() {

    const [user, setUser] = useState([]);

    useEffect(() => {
        const session = JSON.parse(localStorage.getItem('supabase.auth.token'));
        setUser(session.currentSession.user)
    }, []);
    return (
        <div>
            {user.email}
        </div>
    );
}