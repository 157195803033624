import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { ListGroup } from "react-bootstrap";

export default function Balance({ wallets, transactions }){

    const [balance, setBalance] = useState(0);
    const [localWallets, setLocalWallets] = useState([]);

    const calculateBalance = (transactions) => {
        let currentBalance = 0;
        transactions.forEach(transaction => {
            currentBalance = currentBalance + transaction.amount;
            setBalance(currentBalance)
        })
    }

    const calculateWalletsBalance = (wallet, transactions) => {
        const walletTransactions = transactions.filter(transaction => {
            return transaction.walletId === wallet.id
        })

        let currentWalletBalance = 0;
        walletTransactions.forEach(transaction => {
            currentWalletBalance = currentWalletBalance + transaction.amount
        });
        return currentWalletBalance;
    }
    
    useEffect(() => {
        calculateBalance(transactions);
    }, [transactions]);
    
    useEffect(() => {
        const setWalletsBalance = (wallets, transactions) => {
            wallets.forEach(wallet => {
                let currentWalletBalance = calculateWalletsBalance(wallet, transactions);

                wallets = [...wallets].map(object => {
                    if (object.id === wallet.id) {
                        return {
                            ...object,
                            balance: currentWalletBalance
                        }
                    }
                    else return object;
                });

                setLocalWallets(wallets);
            });
        }
        setWalletsBalance(wallets, transactions);
    }, [wallets, transactions]);
    

    return (
        <ListGroup>
            <ListGroup.Item>
                <div className="d-flex justify-content-between">
                    <div>Общий баланс</div>
                    <div className="text-end">
                        <NumericFormat
                            value={balance}
                            thousandsGroupStyle="thousand"
                            thousandSeparator=" "
                            displayType="text"
                        /> р.
                    </div>
                </div>
            </ListGroup.Item>
            {localWallets.map(wallet=>{
                return (
                    <ListGroup.Item key={wallet.id}>
                        <div className="d-flex justify-content-between">
                            <div>{wallet.name} </div>
                            <div className="text-end">
                                <NumericFormat
                                    value={wallet.balance}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=" "
                                    displayType="text"
                                /> р.
                            </div>
                        </div>
                    </ListGroup.Item>
                )
            })}
        </ListGroup>
    )
}