import { Row, Col } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import ProjectsList from '../components/ProjectsList';
import Project from '../components/Project';
import { useState } from 'react';
import { getProjects } from '../functions/projects';

export default function ProjectsPage() {
    const [activeProject, setActiveProject] = useState([]);
    const [projects, setProjects] = useState([]);

    const setProjectList = () => {
        getProjects().then((result) => {
            setProjects(result);
        });
    }

    return (
        <>

            <Row>
                <Col lg={3}>
                    <ProjectsList projects={projects} setProjectList={setProjectList} setActiveProject={setActiveProject}/>
                </Col>
                <Col lg={9}>
                    <Routes>
                        <Route
                            path=":projectId//*"
                            element={<Project activeProject={activeProject} />}
                        />
                    </Routes>
                </Col>
            </Row>
        </>
    );    
}