import { Button, Form } from 'react-bootstrap';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import signIn from '../functions/signIn';

export default function Login() {

    const [userEmail, setUserEmail] = useState('')
    const [error, setError] = useState('')
    const [validated, setValidated] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    async function handleLogInSubmit(e) {
        setIsDisabled(true);
        setValidated(false);
        setError('');
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setIsDisabled(false);
        } else {
            const { error } = await signIn(userEmail);
            setError(error?.message);
            setValidated(!error);
            setIsDisabled(false);
        }
    }

    return (
        <div className="container">
            <div className='row justify-content-center align-items-center mt-5'>
                <div className="col-lg-4">
                    <Card>
                        <Card.Body>
                            <h1 className="h4">ArkCore</h1>
                            <p>
                                На ваш емейл придёт письмо,
                                <br />
                                перейдите по ссылке из письма.
                            </p>
                            <Form onSubmit={handleLogInSubmit} >
                                    <Form.Group className="mb-3" controlId="userEmail">
                                        <Form.Control 
                                            type="mail" 
                                            placeholder="Введите ваш емейл" 
                                            value={userEmail} 
                                            onChange={e => setUserEmail(e.target.value)} 
                                            required 
                                            isValid={validated && !error}
                                            isInvalid={error}
                                        />
                                        <Form.Control.Feedback type="valid">Письмо отправлено!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            {error}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <div className="text-end">
                                        <Button variant="primary" type="submit" disabled={isDisabled}>
                                            Получить письмо <i className="bi bi-arrow-right"></i>
                                        </Button>
                                    </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}