import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';

Font.register({
    family: "Roboto",
    src:
        "https://cdnjs.cloudflare.com/ajax/libs/infusion/2.0.0/assets/src/lib/fonts/OpenSans-Regular.ttf"
});

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'Roboto',
        fontSize: '10px',
        padding: '50px 30px'
    },
    header: {
        display: 'flex',
        column: {
            // fontSize: '10px',
        }
    },
    contractor: {
        border: '1px solid #ccc'
    },
    contractorBank: {
        borderBottom: '1px solid #ccc'
    }
});

const text = [
    'ИП Ковалев Артем Сергеевич',
    '109377, г.Москва, Рязанский пр - т, 48',
    '+ 7(495) 177 - 5047'
]
    
                                    

export default function InvoicesPage() {
    return (
        <div>
            <PDFViewer className='w-100' style={{height: '800px'}}>
                <Document>
                    <Page size="A4" style={styles.page}>
                        <View style={styles.header}>
                            <View style={styles.header.column}>
                                {text.map((text, i) => {
                                    return (
                                        <Text key={i}>{text}</Text>
                                    )
                                })}
                            </View>
                        </View>
                        <View style={styles.contractor}>
                            <View style={styles.contractorBank}>
                                <Text>АО "ТИНЬКОФФ БАНК",</Text>
                                <Text>Банк получателя</Text>
                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </div>
    )
}