import * as React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "./features/auth/pages/Login";
import ProfilePage from "./features/profile/pages/ProfilePage";
import ProjectsPage from "./features/projects/pages/ProjectsPage";
import Home from "./features/home/Home";
import FinPage from "./features/finance/pages/FinPage";
import FofPage from "./features/finance/fof/pages/FofPage"
import './assets/scss/style.scss';
import RequireAuth from './features/auth/components/RequireAuth';
import ClientsPage from "./features/clients/pages/clientsPage";
import OraPage from "./features/ora/pages/OraPage";
import InvoicesPage from "./features/invoices/pages/InvoicesPage";


function NotFoundPage() {
    return (<div>Not Found</div>)
}

export default function App() {

    return (
        <Routes>
            <Route path="/" element={<RequireAuth><Home /></RequireAuth>  }>

                <Route
                    path="projects//*"
                    element={ <ProjectsPage /> }
                />

                <Route
                    path="clients//*"
                    element={<ClientsPage /> }
                />

                <Route
                    path="ora//*"
                    element={<OraPage /> }
                />

                <Route
                    path="fin//*"
                    element={<RequireAuth role="admin"> <FinPage /> </RequireAuth>}
                >
                    <Route
                        path="fof//*"
                        element={ <FofPage /> }
                    />
                </Route>

                <Route
                    path="invoices//*"
                    element={<RequireAuth role="admin"> <InvoicesPage /> </RequireAuth>}
                >
                </Route>

                <Route
                    path="profile/"
                    element={<ProfilePage />}
                />
            </Route>
            <Route path="/login/" element={<Login />} />
                
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    )
}