import { getTransactions } from '../functions/transactionsSupabase';
import React, { useState, useEffect } from 'react';
import AddTransactionForm from '../components/AddTransactionForm';
import TransactionsTable from '../components/TransactionsTable';
import { getWallets } from '../functions/walletsSupabase';
import { getСounterparties } from '../functions/counterpartySupabase';
import Balance from '../components/Balance';

export default function TransactionsPage() {
    const [transactions, setTransactions] = useState([]);
    const [wallets, setWallets] = useState([]);
    const [counterparties, setСounterparties] = useState([]);
    
    const setTransactionArray = async () => {
        await getTransactions().then((result) => {
            setTransactions(result);
        });
    }
    
    const setWalletsArray = async () => {
        await getWallets().then((result) => {
            setWallets(result);
        });
    }
    
    const setСounterpartiesArray = async () => {
        await getСounterparties().then((result) => {
            setСounterparties(result);
        });
    }

    useEffect(() => {
        setWalletsArray();
        setСounterpartiesArray();
        setTransactionArray();
    }, [])
    
    return (
        <>  
            <div className="row">
                <div className="col-lg-4">
                    <div className="mb-3">
                        <Balance transactions={transactions} wallets={wallets}/>
                    </div>
                </div>
                <div className="col-lg-6 offset-lg-2">
                    <div className="mb-3">
                        <AddTransactionForm updateTransactions={setTransactionArray} wallets={wallets} counterparties={counterparties} />
                    </div>
                </div>
            </div>
            <TransactionsTable transactions={transactions} wallets={wallets} counterparties={counterparties}/>
        </>
    );
}