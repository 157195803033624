import getClients from "../functions/getClients"
import { useEffect, useState } from 'react'

export default function ClientsPage() {
    const [clients, setClients] = useState([])

    useEffect(() => {

        const clientsResult = getClients();
        clientsResult.then(result => {
            setClients(result)
        });
    }, [])
    

    return (
        <ul>
            {clients.map((client, index) => (
                <li key={index}>{client.name}</li>
            ))}
        </ul>
    )
}
