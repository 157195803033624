import { supabase } from "../../../../supabase/functions/supabaseConnect";

const getTransactions = async () => {
    const { data } = await supabase
        .from('fin_transactions')
        .select()
        .order('date', { ascending: false });
    return data;
}

const insertTransaction = async ({ date, amount, purpose, walletId, counterpartyId }) => {
    const { data , error } = await supabase
        .from('fin_transactions')
        .insert({
            date: date,
            amount: amount.replace(/\s/g, ''),
            purpose: purpose,
            walletId: walletId,
            counterpartyId: counterpartyId
        })
        .single();
    console.log(error);
    return data;
}

export { getTransactions, insertTransaction };