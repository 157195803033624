import { Row, Col, Form, Button } from 'react-bootstrap';
import { insertTransaction } from '../functions/transactionsSupabase';
import { useState, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NumericFormat } from 'react-number-format';
import sendMessage from '../../../../telegram/finarkBot/functions/sendMessage';



export default function AddTransactionForm({ updateTransactions, wallets, counterparties }) {
    
    const [amount, setAmount] = useState('');
    const [purpose, setPurpose] = useState('');
    const [date, setDate] = useState(new Date());
    const [walletId, setWalletId] = useState(0);    
    const [counterpartyId, setCounterpartyId] = useState(0);    
    
    const addTransactionSubmit = e => {
        e.preventDefault();

        const wallet = wallets.find(wallet => wallet.id === parseInt(walletId))
        const counterparty = counterparties.find(counterparty => counterparty.id === parseInt(counterpartyId))

        sendMessage(`Транзакция на сумму ${amount}р. Kошелёк - ${wallet.name}. Контрагент - ${counterparty.name}. Назначение - ${purpose}`);
        insertTransaction({
            date: date,
            amount: amount, 
            purpose: purpose,
            walletId: walletId,
            counterpartyId: counterpartyId,
        }).then(() => {
            updateTransactions();
        })
    }

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <Form.Control
            className='mb-3'
            defaultValue={date}
            onClick={onClick} 
            ref={ref} 
        />
    ));


    return (
        <>
            <Form onSubmit={addTransactionSubmit}>
                <Row>
                    <Col lg={6}>
                        <DatePicker 
                            selected={date} 
                            onChange={(date) => setDate(date)} 
                            customInput={<ExampleCustomInput />}
                        />
                    </Col>
                    <Col lg={6}>
                        <NumericFormat
                            className='mb-3 form-control'
                            value={amount}
                            onDoubleClick={() => setAmount('')}
                            onChange={e => setAmount(e.target.value)}
                            thousandsGroupStyle="thousand" 
                            thousandSeparator=" "
                            placeholder='Введите сумму'
                            required
                        />
                    </Col>
                    <Col lg={6}>
                        <Form.Control
                            className='mb-3'
                            value={purpose}
                            onChange={e => setPurpose(e.target.value)}
                            onDoubleClick={() => setPurpose('')}
                            placeholder='Введите назначение'
                            required
                        />
                    </Col>
                    <Col lg={6}>
                        <Form.Select
                            className='mb-3'
                            onChange={e => setCounterpartyId(e.target.value)}
                            required
                        >   
                            <option>Выберете контрагента</option>
                            {counterparties.map((counterparty, i) => {
                                return (
                                    <option key={i} value={counterparty.id}>
                                        {counterparty.name}
                                    </option>
                                )
                            })}                               
                        </Form.Select>
                    </Col>
                    <Col lg={6}>
                        <Form.Select
                            className='mb-3'
                            value={walletId}
                            onChange={e => setWalletId(e.target.value)}
                            required
                        >
                            <option>Выберете кошелёк</option>
                            {wallets.map((wallet, i) => {
                                return(
                                    <option key={i} value={wallet.id}>
                                        {wallet.name}
                                    </option>
                            )})}                            
                        </Form.Select>
                    </Col>
                    <Col lg={6}>
                        <Button type='submit' className='w-100'>
                            Отправить
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}