import { Table, Card, Form, InputGroup } from 'react-bootstrap';
import React from 'react';
import { useTable, useGroupBy, useSortBy, useExpanded, useFilters } from 'react-table';
import { matchSorter } from 'match-sorter';

/* eslint-disable */

export default function TransactionsTable({ transactions, wallets, counterparties }) {

    const data = React.useMemo(
        () => {
            return transactions.map(item => {
                const obj = Object.assign({}, item);
                
                obj.wallet = wallets.find(wallet => {
                    return wallet.id === item.walletId;
                });

                obj.counterparty = counterparties.find(counterparty => {
                    return counterparty.id === item.counterpartyId;
                });

                return obj;
            });
        },
        [transactions, wallets, counterparties]
    )

    const columns = React.useMemo(
        () => [
            {
                header: 'Дата',
                accessor: 'date',
                aggregate: 'uniqueCount',
                aggregated: ({ value }) => value,
            },
            {
                header: 'Сумма',
                accessor: 'amount',
                aggregate: 'sum',
                aggregated: ({ value }) => value,
                filter: NumberRangeColumnFilter,
                filter: 'between'
            },
            {
                header: 'Назначение',
                accessor: 'purpose',
                filter: 'fuzzyText',
                aggregate: 'uniqueCount',
                aggregated: ({ value }) => value,
            },
            {
                header: 'Контрагент',
                accessor: 'counterparty.name',
                filter: 'fuzzyText',
                aggregate: 'uniqueCount',
                aggregated: ({ value }) => value,
            },
            {
                header: 'Кошелёк',
                accessor: 'wallet.name',
                filter: 'fuzzyText',
                aggregate: 'uniqueCount',
                aggregated: ({ value }) => value,
            },
        ],
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter },
    }) {
        const count = preFilteredRows.length

        return (
            <Form.Control
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                }}
                placeholder={`(${count}) Поиск...`}
            />
        )
    }

    function NumberRangeColumnFilter({
        column: { filterValue = [], preFilteredRows, setFilter, id },
    }) {
        const [min, max] = React.useMemo(() => {
            let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
            let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
            preFilteredRows.forEach(row => {
                min = Math.min(row.values[id], min)
                max = Math.max(row.values[id], max)
            })
            return [min, max]
        }, [id, preFilteredRows])

        return (
            <div className='d-flex align-items-center'>
                <InputGroup>
                    <Form.Control
                        value={filterValue[0] || ''}
                        type="number"
                        onChange={e => {
                            const val = e.target.value
                            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                        }}
                        placeholder={`${min}`}
                    />
                    <Form.Control
                        value={filterValue[1] || ''}
                        type="number"
                        onChange={e => {
                            const val = e.target.value
                            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
                        }}
                        placeholder={`${max}`}
                    />
                </InputGroup>
            </div>
        )
    }

    function fuzzyTextFilterFn(rows, id, filterValue) {
        return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
    }

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )


    const tableInstance = useTable({ columns, data, defaultColumn, filterTypes }, useFilters, useGroupBy, useSortBy, useExpanded)


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { groupBy, expanded }
    } = tableInstance

    return (
        <Card>
            <Card.Body>
                <Table {...getTableProps()} className='table-sm' bordered hover>
                    <thead>
                        {headerGroups.map((headerGroup, i) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                                {headerGroup.headers.map((column, i) => (
                                    <th key={i}>
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        {headerGroups.map((headerGroup, i) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                                {headerGroup.headers.map((column, i) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                                        {column.canGroupBy ? (
                                            // If the column can be grouped, let's add a toggle
                                            <span {...column.getGroupByToggleProps()}
                                                className='me-2'
                                            >
                                                {column.isGrouped ? <i className="bi bi-chevron-bar-expand"></i> : <i className="bi bi-chevron-bar-contract"></i>}
                                            </span>
                                        ) : null}
                                        {column.render('header')}
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? <i className="bi bi-arrow-down-short"></i> : <i className="bi bi-arrow-up-short"></i>) : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {/* Apply the table body props */}
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} key={i}>
                                    {row.cells.map((cell, i) => {
                                        return (
                                            <td
                                                // For educational purposes, let's color the
                                                // cell depending on what type it is given
                                                // from the useGroupBy hook
                                                {...cell.getCellProps()}
                                                key={i}
                                                className={cell.isGrouped ? 'table-success' : cell.isAggregated ? 'table-warning' : cell.isPlaceholder ? 'table-danger' : ''}
                                            >
                                                {cell.isGrouped ? (
                                                    // If it's a grouped cell, add an expander and row count
                                                    <>
                                                        <span {...row.getToggleRowExpandedProps()} className='me-2'>
                                                            {row.isExpanded ? <i className="bi bi-caret-down-fill"></i> : <i className="bi bi-caret-right-fill"></i>}
                                                        </span>{' '}
                                                        {cell.render('Cell')} ({row.subRows.length})
                                                    </>
                                                ) : cell.isAggregated ? (
                                                    // If the cell is aggregated, use the Aggregated
                                                    // renderer for cell
                                                    cell.render('Aggregated')
                                                ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                                                    // Otherwise, just render the regular cell
                                                    cell.render('Cell')
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
}
