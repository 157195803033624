import { useEffect } from "react"
import axios from "axios";

export default function OraPage() {

    const client_id = 'VfydP5nQS0qvYnJINEBgwdn8GkOKHwf6m5FqnjLfubLKIIcH';
    const client_secret = 'wpPdU094AdQbWoJwyzg5q0pcYr8vbRjCROu0ZzvVvf420nzASOfZ1krEY1mCPS65ttLVYuYjmyBhJk';

    const redirect_uri = 'http://localhost:3000/ora/'

    const grant_type = 'authorization_code'

    useEffect(() => {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get('code');

        if (code !== null) {
            console.log(code);

            axios.post('https://api.ora.pm/oauth/token', {
                "client_id": client_id,
                "client_secret": client_secret,
                "code": code,
                "redirect_uri": redirect_uri,
                "grant_type": grant_type
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }, [])
    
    return(
        <div>
            <a href={"https://app.ora.pm/authorize?response_type=code&client_id=" + client_id + "&redirect_uri=" + redirect_uri + "&state=TW9uIERlYyAyMyAyMDE5IDE3OjUxOjM2IEdNVCswMjAwIChFYXN0ZXJuIEV1cm9wZWFuIFN0YW5kYXJkIFRpbWUp&realm="}>Авторизоваться</a>
        </div>
    )
}