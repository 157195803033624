import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import { SupabaseProvider } from './features/supabase/components/SupabaseProvider';
import { AuthProvider } from './features/auth/components/AuthProvider';
import { ThemeProvider } from './features/theming/components/ThemeProvider';
import { CookiesProvider } from 'react-cookie';

import App from './App';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <CookiesProvider>
    <SupabaseProvider>
      <BrowserRouter>
        <AuthProvider>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </AuthProvider>
      </BrowserRouter>
    </SupabaseProvider>
  </CookiesProvider>
);