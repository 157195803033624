import { Outlet } from "react-router-dom";
import MainNav from "../navigation/components/MainNav";
import { Container } from "react-bootstrap";

export default function Home() {
    return (
        <>
            <MainNav />
            <Container>
                <Outlet/>
            </Container>
        </>
    );
}