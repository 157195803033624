import { useAuthContext } from "./AuthProvider"
import { Navigate } from "react-router-dom"

export default function RequireAuth({ children, role }) {

    const {session, isAdmin} = useAuthContext();    

    if (!session) {
        return <Navigate to='/login' />
    }

    if (role === 'admin') {
        if (isAdmin) {
            return children
        } else {
            return null;
        }
    } 

    return children
}