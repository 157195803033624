import axios from "axios";


const telegramURL = 'https://api.telegram.org/bot'
const botToken = '5904100079:AAGvKblpZ_kJHyXCUHMSTo7c5Ao9lkIAJ-E'
const sendMessageMethod = '/sendMessage'

const methodURL = telegramURL+botToken+sendMessageMethod;

export default function sendMessage(messageText) {

    axios.post(methodURL, {
        "chat_id": "397048670",
        "text": messageText
    })
    .then(function (response) {
        console.log(response);
    })
    .catch(function (error) {
        console.log(error);
    });

    axios.post(methodURL, {
        "chat_id": "181909760",
        "text": messageText
    })
    .then(function (response) {
        console.log(response);
    })
    .catch(function (error) {
        console.log(error);
    });

}